@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/skel';

/*
  Venue by Pixelarity
  pixelarity.com | hello@pixelarity.com
  License: pixelarity.com/license
*/

/* Basic */

  body {
    &:before, &:after {
      display: none;
    }
  }
